/* jshint esversion: 11 */
define([
    "underscore",
    "jquery",
    "util",
    "supportedProducts",
    "service/cloudStatus",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string",
    "config"
], function (
    _,
    $,
    Util,
    SupportedProducts,
    CloudStatus,
    I18NStringResource,
    DojoString,
    AppConfig //named AppConfig b/c lots of "config" references in this file
) {
    class WorkflowService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
            this._listConfigsByProductCache = new Map()
            this._listConfigsByProductShouldLoadCache = new Map()
        }

        getDAO () { return this.dao; }

        // used by listConfigsByProduct
        listConfigsByProductCache () { return this._listConfigsByProductCache }
        listConfigsByProductShouldLoadCache () { return this._listConfigsByProductShouldLoadCache }
        setLoadingListConfigsByProductStatusLock (status) { this.listConfigsByProductStatus = status }
        getLoadingListConfigsByProductStatusLock () { return this.listConfigsByProductStatus }

        async details (configId, credentialId, location) {
            Util.consoleLogTrace('workflow.details', 'called');
            //if a configId is passed, returns the Step1/Step2 data
            if (!Util.isMD5(configId)) {
                throw new TypeError('Invalid configId argument: ' +configId);
            }
            if (!credentialId || typeof credentialId !== 'string') {
                throw new TypeError('Invalid credentialId argument');
            }
            // split up credential (works for AWS and Azure)
            const { accountId, subscriptionId } = Util.splitCredIdForAzure(credentialId)
            if (!location || typeof location !== 'string') {
                throw new TypeError('Invalid location argument');
            }
            let queryParams = {
            show_all: true,
            rules_id: configId,
            credential_id: accountId,
            cloud_location_id: location
            }
            if (subscriptionId) {
                queryParams.subscription_id = subscriptionId
            }
            const detailData = await this.getDAO().getCCAPI('workflow', 'type', 'rules', undefined, queryParams, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            return detailData;
        }

        async cloudAccess (configId) {
              Util.consoleLogTrace("workflow.cloudAccess", "called");
              //if a configId is passed, returns the access data for running resources
              if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
              }
              //search(dataType, facets, baseParams) {
              try {
                const data = await this.search("config", {}, {config_id: configId, refresh:true});
                if (data && Array.isArray(data) && data.length > 0) {
                  return {msg:data[0].cloud_access};
                }
              } catch (error) {
                Util.consoleLogError("workflow.cloudAccess", error);
              }
              return await this.getDAO().getCCAPI("workflow", "resource", configId, "access", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async updateTerminationPolicy (configId, terminationPolicy, allowedValues, product = "matlab") {
            Util.consoleLogTrace("workflow.updateTerminationPolicy", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!terminationPolicy) {
                throw new TypeError("Invalid terminationPolicy argument");
            }
            if (!allowedValues) {
                throw new TypeError("Invalid allowedValues argument");
            }
            if (!allowedValues[terminationPolicy]) { //separate if statement so doesn't go into Util else
                throw new TypeError(`terminationPolcy not allowed: ${terminationPolicy}`);
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            let params;
            if (terminationPolicy) {
                params = {policy: terminationPolicy};
            }
            const data = await this.getDAO().postCCAPI("workflow", "resource", configId, "termination-policy", params);
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async outputs (configId) {
            Util.consoleLogTrace("workflow.outputs", "called");
            //if a configId is passed, returns the access data for running resources
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            //search(dataType, facets, baseParams) {
            try {
                const data = await this.search("config", {}, {config_id: configId, refresh:true});
                if (data !== undefined && Array.isArray(data) && data.length > 0) {
                    return {msg:data[0].cloud_outputs};
                }
            } catch (err) {
                Util.consoleLogError("workflow.outputs", err);
            }
            return await this.getDAO().getCCAPI("workflow", "resource", configId, "outputs", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async addCloudAccess (configId, accessId, ipAddresses, fromPort, toPort, protocol, self, desc) {
            Util.consoleLogTrace("workflow.addCloudAccess", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            let params = {
            access_id:accessId,
            ip_address: ipAddresses,
            current_location: (self === true).toString(),
            desc: desc,
            from_port: fromPort,
            to_port: toPort,
            protocol: protocol
            };
            for (const [key, value] of Object.entries(params)) {
                if (!value) {
                    delete params[key];
                }
            }
            return await this.getDAO().postCCAPI("workflow", "resource", configId, "access", params);
        }

        async removeCloudAccess (configId, accessId, ipAddresses, dataset) {
            Util.consoleLogTrace("workflow.removeCloudAccess", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            let params = {};
            if (accessId) {
                params.access_id=accessId;
            }
            if (ipAddresses) {
                params.ip_address = ipAddresses;
            }
            if (dataset) {
                params.from = dataset.from;
                params.to = dataset.to;
                params.protocol = dataset.protocol;
            }
            return await this.getDAO().deleteCCAPI("workflow", "resource", configId, "access", params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async cloudEvents (id) {
            Util.consoleLogTrace("workflow.cloudEvents", "called");

            //if a configId is passed, returns the event data for running resources
            if (!Util.isMD5(id)) {
                throw new TypeError("Invalid id argument");
            }
            return await this.getDAO().getCCAPI("workflow", "resource", id, "events", undefined,{fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async list (dataType, columns, additionalFilters) {
            Util.consoleLogTrace("workflow.list", "called");
            if (dataType && dataType !== "template" && dataType !== "rules" && dataType !== "config") {
                throw new TypeError("Invalid dataType argument");
            }
            let params;
            if (additionalFilters) {
                params = additionalFilters;
            } else {
                params = {};
            }
            if (columns) {
                params.params = columns;
            }
            return await this.getDAO().getCCAPI("workflow", "type", dataType, undefined, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        search (dataType, facets, baseParams) {
            Util.consoleLogTrace("workflow.search", "called");
            if (!dataType || (dataType !== "template" && dataType !== "rules" && dataType !== "config")) {
                throw new TypeError("Invalid dataType argument");
            }
            if (facets && typeof facets !== "object" ) {
                throw new TypeError("Invalid facets argument");
            }
            let cols = "";
            for (let [col, value] of Object.entries(facets)) {
                if (cols) {
                    cols += ",";
                }
                cols += `${col}=${value}`;
            }
            let params = {};
            if (baseParams) {
                params = baseParams;
            }
            params.params=cols;
            return this.getDAO().getCCAPI("workflow", "type", dataType, undefined, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getBody (dataType, facets) {
            Util.consoleLogTrace("workflow.getBody", "called");
            if (!dataType || (dataType !== "template" && dataType !== "rules" && dataType !== "config")) {
                throw new TypeError("Invalid dataType argument");
            }
            if (facets && typeof facets !== "object" ) {
                throw new TypeError("Invalid facets argument");
            }
            let cols = "body";
            for (let [col, value] of Object.entries(facets)) {
                cols += `,${col}=${value}`;
            }
            return await this.getDAO().getCCAPI("workflow", "type", dataType, undefined, {params:cols}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async defaultRuleAndAdditionalInfoByProduct (product) {
            Util.consoleLogTrace("workflow.defaultRuleAndAdditionalInfoByProduct", "called");
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            let defaultRuleId, location, credentialId, credentialTypeId, description, defaultRule;
            let defaultRulesForProductArray = [];
            try {
                defaultRulesForProductArray = await this.getDAO().getCCAPI("workflow", "type", "rules", undefined, {
                    params:`product=${product}`,
                    show_all: true,
                    rules_default_only: true,
                    //refresh: true,
                    // reduce_rules_settings: "true"
                }, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            } catch (error) {
                Util.consoleLogError('defaultRuleAndAdditionalInfoByProduct', error);
            }
            if (defaultRulesForProductArray.length) {
                defaultRule = defaultRulesForProductArray[0];
                defaultRuleId = defaultRule.id;
                if (defaultRule.params && defaultRule.params.body && defaultRule.params.body.params){
                  for (const p of Object.values(defaultRule.params.body.params)) {
                    if (!p.config || !p.config.default) {
                      continue;
                    }
                    switch (p.id) {
                      case "mw-cloud-location":
                        location = p.config.default;
                      break;
                      case "mw-credential-id":
                        if (defaultRule.params.credential_type) {
                          for (const [ctId, ctDesc] of Object.entries(defaultRule.params.credential_type)) {
                            credentialTypeId = ctId;
                            description = ctDesc;
                            break;
                          }
                        }
                        credentialId = p.config.default;
                      break;
                    }
                  }
                }
            }
            return [defaultRuleId, location, credentialId, credentialTypeId, description, defaultRule];
        }

        async defaultRule (credentialTypeId) {
            Util.consoleLogTrace("workflow.defaultRule", "called");
            if (!Util.isMD5(credentialTypeId)) {
                throw new TypeError("Invalid credentialTypeId argument: " + credentialTypeId);
            }
            return await this.getDAO().getCCAPI("workflow", "type", "rules", undefined, {credential_type_id: credentialTypeId}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async defaultRuleByProduct (product, credentialTypeId) {
            Util.consoleLogTrace("workflow.defaultRuleByProduct", "called");
            // new call
            return await this.getDAO().getCCAPI("workflow", "type", "rules", undefined, {params:`product=${product}`, show_all: false, rules_default_only: true, credential_type_id:credentialTypeId}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async listTemplates () {
            Util.consoleLogTrace("workflow.listTemplates", "called");
            return await this.list("template", "description,cloud_provider,url");
        }

        async listRules () {
            Util.consoleLogTrace("workflow.listRules", "called");
            return await this.list("rules", "aws_region,azure_location,cloud_network_type,description,license_type,operating_system,product,version,highlighted_features");
        }

        async listConfigs (scan=false) {
            Util.consoleLogTrace("workflow.listConfigs", "called");
            let params = {show_all:true};
            if (scan) {
                params.scan=true;
            }
            return await this.getDAO().getCCAPI("workflow", "type", "config", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async listRulesByProduct (product) {
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            Util.consoleLogTrace("workflow.listRulesByProduct", "called with " + product);
            let params = {params:"product="+product+",operating_system,version,description,cloud_provider,cloud_location,credential_type"};
            return await this.getDAO().getCCAPI("workflow", "type", "rules", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getRuleById (ruleId, location, showAll = true, skipLookups = false, reduceSettings = false) {
            Util.consoleLogTrace("workflow.getRuleById", `called with ruleId: ${ruleId}`);
            if (!ruleId || typeof ruleId !== 'string') {
                throw new TypeError("Invalid ruleId argument");
            }
            let params = {rules_id: ruleId, show_all: true};
            if (location && typeof location !== 'string') {
                throw new TypeError("Invalid location argument");
            }
            if (location) {
                params["mw-cloud-location"] = location;
            } else if (!showAll) {
                params.show_all=false;
            }
            if (reduceSettings || skipLookups) {
                params.skip_enrichment_defaults = true;
                params.skip_enrichment_cloud = true;
            }
            if (reduceSettings) {
                params.reduce_rules_settings = true;
            }
            return await this.getDAO().getCCAPI("workflow", "type", "rules", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }


        async listConfigsByProduct (product, scan = false) {
            const fnName = "workflow.listConfigs";
            Util.consoleLogTrace(fnName, "called with " + product);
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            const timeout = 5 * 60 * 1000 // 5 min
            const params = {
                params: `product`,
                show_all: true,
                refresh: true, //this causes a synchrous load ... refresh=false returns what's currently in DD
                skip_reload: true, //with skip reload=true, it won't force an async reload
                skip_enrichment_cloud: true,
                skip_enrichment: true,
                reduce_rules_settings: true
            };
            if (scan) {
                params.scan = true;
            }

            if (!AppConfig.isProductSearchWithRetriesEnabled()) {
                params.params += "=" + product;
                return await this.getDAO().getCCAPI("workflow", "type", "config", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: timeout});
            }

            const retries = 3;
            const loopSleep = 200;
            const max = loopSleep;
            const expBackoffSleepFactor = 200;
            const min=0;
            const entropySleepTime = Math.floor(Math.random() * (max - min + 1) + min);
            const dataKey = JSON.stringify(params)

            //randomize the threads so if they all came from the UI at hte same time, they don't all hit the if at the same time
            await Util.sleep(entropySleepTime);

            while (this.getLoadingListConfigsByProductStatusLock()) {
                if (!this.listConfigsByProductShouldLoadCache().get(dataKey)) {
                    //first thread blocks other threads
                    this.listConfigsByProductShouldLoadCache().set(dataKey, true)
                    this.listConfigsByProductCache().delete(dataKey)
                } else {
                    ///other threads are waiting
                    await Util.sleep(loopSleep);
                }
            }
            this.setLoadingListConfigsByProductStatusLock(true)

            for (let i = 0; i < retries; i++) {
                const lastIteration = i + 1 === retries
                try {
                    let data = this.listConfigsByProductCache().get(dataKey)
                    if (lastIteration) {
                      return data  // backwards compatibility: return any data on the last loop
                    }
                    if (data === undefined) {
                        // no cached data, so load it from API and save it to the cache
                        data = await this.getDAO().getCCAPI("workflow", "type", "config", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: timeout});
                        this.listConfigsByProductCache().set(dataKey, data)
                        Util.consoleLogTrace(fnName, "loading data", (i+1), dataKey);
                    }

                    if (data === undefined) {
                        throw new Error(fnName + " timeout " + timeout + "ms")
                    }

                    //filter to just the product
                    let tmpData = [];
                    for (const item of data) {
                        if (item.params.product === product) {
                            tmpData.push(item)
                        }
                    }
                    return tmpData;

                } catch (error) {
                    if (lastIteration) {
                        throw error // backwards compatibility: throw any caught error on the last loop
                    }
                    if (params.scan) {
                        params.scan = false; //don't kick off scan multiple times
                    }
                    Util.consoleLogTrace(fnName, "retrying", i, "->", i+1, params.config_id, params.params);
                } finally {
                    this.setLoadingListConfigsByProductStatusLock(false)
                    await Util.sleep(loopSleep + 100); //give items waiting for data a chance to grab it
                    this.listConfigsByProductCache().delete(dataKey)
                    this.listConfigsByProductShouldLoadCache().delete(dataKey) //reset the data so next call to this fn will delete the cache
                }
                await Util.sleep((i*i)*expBackoffSleepFactor); //exponential backoff
            }
        }

        async listConfig (configId, scan = false) {
            const fnName = "workflow.listConfig";
            Util.consoleLogTrace(fnName, "called");
            const timeoutWithoutRetry = 5 * 60 * 1000 //5m
            if (!configId || typeof configId !== 'string') {
                throw new TypeError("Invalid configId argument");
            }
            let params = {
                config_id: configId,
                show_all:true,
                refresh:true, //this causes a synchrous load ... refresh=false returns what's currently in DD
                skip_reload: true //with skip reload=true, it won't force an async reload
            };
            if (scan) {
                params.scan = true;
            }
            if (!AppConfig.isLoadConfigByIdWithRetriesEnabled()) {
                return await this.getDAO().getCCAPI("workflow", "type", "config", undefined, params, {fetchAbortable: true, fetchTimeoutMillis: timeoutWithoutRetry});
            }

            const retries = 3
            const timeoutWithRetry = 5 * 60 * 1000 //5m
            const expBackoffSleepFactor = 250

            for (let i = 0; i < retries; i++) {
                const lastIteration = i + 1 === retries
                try {
                    let data = await this.getDAO().getCCAPI("workflow", "type", "config", undefined, params, { fetchAbortable: true, fetchTimeoutMillis: timeoutWithRetry });
                    if (lastIteration) {
                        return data; // backwards compatibility: return any data on the last loop
                    }
                    if (!data) {
                        throw new Error(fnName + " timeout " + timeout + "ms")
                    }
                    if (!data.length) {
                        try {
                            await this.getDAO().getCCAPI("workflow", "resource", configId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: timeoutWithoutRetry});
                        } catch (error) {
                            if (typeof error === 'object' && error.status === 404 && error.message && error.message.indexOf("runtime.error.nonexistingkey")) {
                                return data; // data was empty, as confirmed by direct load
                            }
                        }
                        throw new Error(fnName + " config id not found " + params.config_id)
                    }
                    if (data.length > 1) {
                        Util.consoleLogTrace(fnName, "returned multiple results for", params.config_id, data)
                        return data; // data came back but not expected to have 2, so return it
                    }
                    if (data[0] && data[0].cloud && data[0].cloud.tags && data[0].cloud_access === null && data[0].cloud.state !== "TERMINATING") {
                        const msg = fnName + " got results for config ID " + params.config_id + " but with cloud data error - retrying"
                        Util.consoleLogTrace(msg, data[0])
                        throw new Error(msg)
                    }
                    return data
                } catch (error) {
                    if (lastIteration) {
                        throw error // backwards compatibility: throw any caught error on the last loop
                    }
                    if (params.scan) {
                        params.scan = false; //don't kick off scan multiple times
                    }
                    Util.consoleLogTrace(fnName, error.toString(), "retrying", i, "->", i+1, params.config_id, params.params);
                }
                await Util.sleep((i*i)*expBackoffSleepFactor); //exponential backoff
            }
        }

        async start (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.start", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            const data = await this.getDAO().putCCAPI("workflow", "resource", configId, "start", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async stop (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.stop", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            const data = await this.getDAO().putCCAPI("workflow", "resource", configId, "stop", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async pause (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.pause", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            const data = await this.getDAO().putCCAPI("workflow", "resource", configId, "pause", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async resume (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.resume", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            let data;
            if (product === "parallel_server") {
                data = await this.getDAO().startParallelServerCluster(configId, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()})
            } else {
                data = await this.getDAO().putCCAPI("workflow", "resource", configId, "resume", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            }
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async clone (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.clone", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            const data = await this.getDAO().postCCAPI("workflow", "resource", configId, "clone");
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async updateAccessProtocol (configId, os, platform, accessProtocolParam, currentAccess, requestedAccess, product = "matlab", yesNoUsed = false) {
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            const rawRequestedAccess = Util.getNativeAccessProtocol(requestedAccess, platform, yesNoUsed);
            if (os === "linux" && currentAccess && currentAccess !== requestedAccess) {
                try {
                    const payload = {};
                    payload[accessProtocolParam] = rawRequestedAccess;
                    await this.update(configId, payload, false, product);
                } catch (error) {
                    Util.consoleLogError("updateAccessProtocol.update", error);
                    Util.notify("ERROR", DojoString.substitute(I18NStringResource.computeDetailsUpdateConfigFailed, [configId]));
                    return;
                }
            }
        }

        async edit (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.edit", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            this.listConfigsByProduct(product); // trigger background refresh
            $.event.trigger("changetoresourcedetailpage:ccwa", {path: `${Util.convertProductToUserFriendlyProduct(product)}/${configId}?edit=true`});
        }

        async deactivate (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.deactivate", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            try {
                await this.getDAO().deleteCCAPI("workflow", "resource", configId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
                this.listConfigsByProduct(product); // trigger background refresh
                return "config deleted";
            } catch (error) {
                let isRealError = true;
                if (error.message) {
                    const m = Util.extractObjectFromJSON(error.message);
                    if (m && m.delete && m.delete.length) {
                        const entry = m.delete[0];
                        if (entry.details && entry.details.includes("state:TERMINATING") && entry.invalid_values && entry.invalid_values.includes('TERMINATING')) {
                            isRealError = false;
                        }
                    }
                }
                if (isRealError) {
                    await this.stop(configId, product); // if error occurs, meaning the stack is still running. Stop the stack.
                }
                return "";
            }
        }

        async hardDelete (configId, product = "matlab") {
            Util.consoleLogTrace("workflow.hardDelete", "called");
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            await this.stop(configId, product);
            await this.deactivate(configId, product);
            //return this.deactivate(configId);
        }

        async create (parentId, params, validateOnly = false, product = "matlab") {
            Util.consoleLogTrace("workflow.create", "called");
            let action = (validateOnly)?"validate":undefined;
            if (parentId && !Util.isMD5(parentId)) {
                //creating a template doesn't require an ID
                throw new TypeError("Invalid parentId argument");
            }
            if (!params || typeof params !== "object" ||  Object.keys(params).length <= 0) {
                throw new TypeError("Invalid params argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            let data = this.getDAO().postCCAPI("workflow", "type", parentId, action, params);
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

        async update (configId, params, validateOnly = false, product = "matlab") {
            Util.consoleLogTrace("workflow.update", "called");
            let action = (validateOnly)?"validate":undefined;
            if (!Util.isMD5(configId)) {
                throw new TypeError("Invalid configId argument");
            }
            if (!params || typeof params !== "object" ||  Object.keys(params).length <= 0) {
                throw new TypeError("Invalid params argument");
            }
            if (!SupportedProducts.isValidProduct(product)) {
                throw new TypeError(`Invalid product argument: ${product}`);
            }
            let data = await this.getDAO().putCCAPI("workflow", "resource", configId, action, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            this.listConfigsByProduct(product); // trigger background refresh
            return data;
        }

    }
    return WorkflowService;
});
