/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/copyToClipboardDialogTemplate",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(_, $, Bootstrap, CopyToClipboardDialogTemplate, Util, I18NStringResource, DojoString) {

  class CopyToClipboardDialog {

    constructor () {
      this.dialogTitle = I18NStringResource.copyToClipboardDialogTitle;
      this.explanation = I18NStringResource.copyToClipboardDialogExplanation;
      this.copyText = "";
      this.copyButtonLabel = I18NStringResource.copyToClipboardDialogCopyButtonLabel;
      this.cancelButtonLabel = I18NStringResource.copyToClipboardDialogCancelButtonLabel;
      this.modalContainerId = "#modalContainer";
    }

    getModalContainer () { return document.querySelector(this.modalContainerId); }

    getDialogElement () { return document.querySelector('div#copyToClipboardDialog'); }

    getTextToCopyInput () {
      let copyInput;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        copyInput = dialogEl.querySelector('input#textToCopy');
      }
      return copyInput;
    }

    getCopyButton () {
      let copyButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        copyButton = dialogEl.querySelector('button#copyButton');
      }
      return copyButton;
    }

    getCancelButton () {
      let cancelButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        cancelButton = dialogEl.querySelector('button#cancelButton');
      }
      return cancelButton;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    setup () {
      const textToCopyInput = this.getTextToCopyInput();
      if (textToCopyInput) {
        textToCopyInput.select();
        textToCopyInput.setSelectionRange(0, 99999); /* For mobile devices */
      }
      const copyButton = this.getCopyButton();
      if (copyButton) {
        copyButton.addEventListener('click', this.doCopy.bind(this));
        copyButton.focus();
      }
    }

    doCopy (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      $.event.trigger("logdduxevent:ccwa", {elementId: 'copyButton', elementType: 'button'});
      const textToCopyInput = this.getTextToCopyInput();
      if (textToCopyInput) {
        textToCopyInput.select();
        textToCopyInput.setSelectionRange(0, 99999); /* For mobile devices */
        // navigator.clipboard is the new, official, correct thing to use.
        // But, not all browsers have implemented support yet.
        // If not supported, use old, obsolete document.execCommand.
        /* istanbul ignore next */
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(textToCopyInput.value)
          .then(this.copySuccessHandler.bind(this))
          .catch(this.copyFailureHandler.bind(this));
        } else {
          if (document.execCommand) {
            document.execCommand('copy', false, undefined);
            this.copySuccessHandler();
          }
        }
      }
      const cancelButton = this.getCancelButton();
      if (cancelButton) {
        cancelButton.click();
      }
    }

    show () {
      const html = CopyToClipboardDialogTemplate({
        dialogTitle: this.dialogTitle,
        explanation: this.explanation,
        copyText: this.copyText,
        copyButtonLabel: this.copyButtonLabel,
        cancelButtonLabel: this.cancelButtonLabel
      });
      const modalContainer = this.getModalContainer();
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          dialogEl.addEventListener('shown.bs.modal', this.setup.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', function() {
            const dialogEl = this.getDialogElement();
            if (dialogEl) {
              const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
              modal.dispose();
            }
          }.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }
      }
    }

    copySuccessHandler (data) {
      /* do nothing */
    }

    copyFailureHandler (error) {
      Util.consoleLogError("copyToClipboard", error);
      this.show();
    }

    copyToClipboard (text) {
      this.copyText = text;
      try {
        Util.copyToClipboard(text, this.copySuccessHandler.bind(this), this.copyFailureHandler.bind(this));
        return;
      } catch (error) {
        Util.consoleLogError("copyToClipboard", error);
        this.show();
      }
    }

    onKeydown (event) {
      if (event) {
        const code = event.keyCode || event.which;
        if (code == 13) { // Enter key
          if (event.preventDefault) {
            event.preventDefault();
          }
          let focusedElement = document.activeElement;
          if (focusedElement && 
              focusedElement.tagName.toUpperCase() === "BUTTON" && (
                focusedElement.className === 'close'
                || focusedElement.id === "cancelButton" ||
                focusedElement.id === "copyButton"
              )
          ) {
            focusedElement.click();
          }
        }
      }
    }

  }

  return CopyToClipboardDialog;
});
