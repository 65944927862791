/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "util",
  "config",
  "supportedProducts",
  "computeResource/collections/collectionItem",
  "computeResource/collections/collectionItemFactory",
  "service/landingPageNavButtons",
  "wizard/ruleManager",
  "wizard/createMatlabWizard",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "dialogs/warningDialog",
  "computeResource/computeValueFormatter",
  "service/cachedDataService"
], function (_, $, Util, Config, SupportedProducts,
             CollectionItem, CollectionItemFactory, LandingPageNavButtons,
             RuleManager, CreateMATLABWizard, I18NStringResource, DojoString,
             WarningDialog, ComputeValueFormatter, CachedDataService) {

  class Collection {

    constructor (args) {
      if (this.constructor === Collection) {
        throw new Error("Cannot instantiate abstract class.");
      } else {
        if (!args || typeof args !== 'object') {
          throw new TypeError("Invalid constructor args");
        }
        this.selector = args.selector;
        this.resourceType = args.resourceType;
        if (!(args.dataService instanceof CachedDataService) && Util.clientCachingIsEnabled() ) {
          this.dataService = new CachedDataService({
            baseService: args.dataService
          });
          this.initLocalDataService();
        } else {
          this.dataService = args.dataService;
        }
        this.proxyURL = args.proxyURL;
        this.autoCancelRenderListMethod = Util.makeAutoCancel(this.renderList.bind(this));
        this.ruleManager = RuleManager.getRuleManagerForProduct(this.resourceType);
        this.collectionUsedOnPage = "computeResourcePageView";
      }
    }

    getDataService () {
      return this.dataService;
    }

    getProxyURL () {
      return this.proxyURL;
    }

    getResourceType () {
      return this.resourceType;
    }

    // the page that this collection is expected to render on.
    // used to determine if the page was suddently changed during rendering.
    getExpectedPage () {
      return this.collectionUsedOnPage;
    }

    initLocalDataService () {
      this.getDataService().init(
        [
          "ui",
          "user",
          "logging",
          "workflow",
          "legacy_parallel/cluster",
          "legacy_parallel/credential"
        ], // components to add to localDataService
        // APIs to cache
        [
          {
            set: "ui/getRulesArrayByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/listConfigsByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/defaultRuleByProduct",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/defaultRule",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/listConfigs",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/listRulesByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/getRuleById",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "user/profile",
            clear: null
          },
          {
            set: "ui/getConfigDetailData",
            clear: [
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "ui/listCredentials",
            clear: null
          }
        ]
      );
    }

    getElement () {
      const element = document.querySelector(this.selector);
      if (!element) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          throw new Error("Invalid Collection selector");
        }
      }
      return element;
    }

    getRuleManager () { return this.ruleManager; }

    /* istanbul ignore next */
    getWizardContainer () { return document.querySelector(`div#${this.getExpectedPage()} div#createWizardContainer`); }

    /* istanbul ignore next */
    getComputeContainer () { return document.querySelector(`div#${this.getExpectedPage()} div#computeContainer`); }

    autoCancelRenderList () {
      return this.autoCancelRenderListMethod();
    }

    createCollectionRow (item) {
      const collectionItem = CollectionItemFactory.createCollectionItem(
        this.getResourceType(),
        {
          config: item,
          collection: this,
          resourceType: this.getResourceType()
        }
      );
      return collectionItem;
    }

    appendRow (row) {
      if (row) {
        let itemToAppend = row;
        if ("getElement" in row) {
          itemToAppend = row.getElement();
        }
        let collection = this.getElement();
        if (collection && itemToAppend) {
          let emptyCollectionWarningRow = CollectionItem.findEmptyCollectionWarningItem(this.getElement());
          if (emptyCollectionWarningRow) {
            collection.removeChild(emptyCollectionWarningRow);
          }
          collection.appendChild(itemToAppend);
        }
      }
    }

    appendResourceItemToList (item) {
      if (item) {
        item.resourceType = this.getResourceType();
        let row = this.createCollectionRow(item);
        this.appendRow(row);
        row.updateStatus(item);
      }
    }

    deleteRow (row) {
      if (row) {
        let itemToDelete = row;
        if ("getElement" in row) {
          itemToDelete = row.getElement();
        }
        let collection = this.getElement();
        if (collection && itemToDelete) {
          collection.removeChild(itemToDelete);
        }
      }
    }

    deleteAllNonHeaderRowsFromList () {
      let list = this.getElement();
      if (list) {
        let rows = list.querySelectorAll('li:not(.table-header)');
        if (rows && rows.length) {
          let row = rows.pop();
          while (row) {
            this.deleteRow(row);
            row = rows.pop();
          }
        }
      }
    }

    addListSpinner () {
      let collection = this.getElement();
      if (collection && !collection.querySelector("div.progressSpinnerContainer")) {
        let loadingSpinner = Util.createSpinner();
        if (loadingSpinner) {
          collection.appendChild(loadingSpinner);
        }
      }
    }

    removeListSpinner () {
      let collection = this.getElement();
      let loadingSpinner = collection.querySelector("div.progressSpinnerContainer");
      if (collection && loadingSpinner) {
        try {
          collection.removeChild(loadingSpinner);
        } catch (error) {
          /* ignore */
        }
      }
    }

    addListHeader () {
      let table = this.getElement();
      table.innerHTML="";
      let header = table.querySelector('li.table-header');
      if (!header) {
        let header = CollectionItemFactory.createCollectionHeader(this.getResourceType());
        table.insertBefore(header, table.first);
      }
    }

    sortRules () {
      let prop = "mw-name";
      let subprop = "product";
      return function(a, b) {
        let firstProp = a.params[prop]?a.params[prop].toLowerCase():null;
        let secondProp = b.params[prop]?b.params[prop].toLowerCase():null;
        let firstSubProp = a.params[subprop]?a.params[subprop].toLowerCase():null;
        let secondSubProp = b.params[subprop]?b.params[subprop].toLowerCase():null;

        if (firstProp > secondProp) {
            return 1;
        } else if (firstProp < secondProp) {
            return -1;
        } else if (firstProp === secondProp) {
          if (firstSubProp > secondSubProp) {
            return 1;
          } else if (firstSubProp < secondSubProp) {
            return -1;
          }
        }
        return 0;
      };
    }

    isWorkflowDataValid (workflowData) {
      return (
        workflowData && Array.isArray(workflowData)
      );
    }

    notifyUserOfComputeResourceDataError (error) {
      let errorMessage = "";
      if (error) {
        errorMessage = error;
      }
      Util.notify('WARNING', I18NStringResource.computeResourcePageErrorLoadingInitialPage + errorMessage);
    }

    async getCollectionItems () {
      return await this.getDataService().workflow.listConfigsByProduct(this.getResourceType());
    }

    async getCollectionItemDetails (configId, useCache = true) {
      let returnData;
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }
      if (!useCache && typeof this.getDataService().clear === 'function') {
        // clear cached data before making call
        this.getDataService().clear("ui/getConfigDetailData");
      }
      let data = await  this.getDataService().ui.getConfigDetailData(configId);
      if (data && typeof data === 'object') {
        returnData = data;
      } else {
        throw new Error("Unrecognized data format");
      }
      return returnData;
    }

    * addCollectionItemsToCollection () {
      // Get the compute resource data
      let workflowData;
      try {
        workflowData = yield this.getCollectionItems();
      } catch (error) {
        this.notifyUserOfComputeResourceDataError(error.message);
      }

      // Compute resource (i.e. workflow) data comes back a an array
      let workflowDataIsValid = yield this.isWorkflowDataValid(workflowData);
      let hasWorkflowData = (workflowDataIsValid && workflowData.length);

      try {
        // Append compute resource (i.e., workflow) data to the list
        yield this.removeListSpinner();
        if (hasWorkflowData) {
          SupportedProducts.hideNoProductResourcesMessage(this.getResourceType());
        } else {
          SupportedProducts.showNoProductResourcesMessage(this.getResourceType());
        }
        if (hasWorkflowData) {
          let computeResourceData = workflowData;
          computeResourceData.sort(this.sortRules()).forEach(this.appendResourceItemToList.bind(this));
        } else {
          if (!workflowDataIsValid) {
            yield this.notifyUserOfComputeResourceDataError(workflowData);
          }
        }
      } catch (error) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          Util.consoleLogError('addCollectionItemsToCollection', error);
        }
      }
    }

    * renderList () {
      try {
        // Add table header, if one does not already exist
        this.addListHeader();

        // Remove all obsolete data
        this.deleteAllNonHeaderRowsFromList();

        // Show the wait spinner below the table header
        yield this.addListSpinner();

        if (this.getResourceType() in SupportedProducts.getSupportedProducts()) {
          const autoCancelAddCollectionItems = Util.makeAutoCancel(this.addCollectionItemsToCollection.bind(this));
          yield autoCancelAddCollectionItems();
        }
      } catch (error) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          Util.consoleLogError("renderList", error);
        }
      }
    }

    startCreateWizardButtonClickListener () {
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      // remove any previous listeners
      $(startWizardButtonSelector).off("click");
      /* istanbul ignore next */
      $(startWizardButtonSelector).on("click", this.startCreateWizard.bind(this));
    }

    stopCreateWizardButtonClickListener () {
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      $(startWizardButtonSelector).off("click");
    }

    processEvent (event) {
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        if (event.currentTarget) {
          let targetButtonId = event.currentTarget.id;
          if (targetButtonId) {
            this.getDataService().logging.logData({
              elementId: targetButtonId,
              elementType: "button",
              eventType: "clicked"
            });
          }
        }
      }
    }

    async startCreateWizard (event, defaultValues) {
      let wizardContainerElement = this.getWizardContainer();
      if (!wizardContainerElement) { throw new Error(`Element not found: 'div#${this.getExpectedPage()} div#createWizardContainer'`); }
      let computeContainer = this.getComputeContainer();
      if (!computeContainer) { throw new Error(`Element not found: 'div#${this.getExpectedPage()} div#computeContainer'`); }

      if (Util.isCreateDuplicateURLEnabled(this.getResourceType())) {
        if (!defaultValues) {
          this.goToResource(null, {path: `${Util.convertProductToUserFriendlyProduct(this.getResourceType())}/create`, trigger: false, replace: false});
        }
      }

      // hide vertical scroll bar b/c of height===100%
      let body = document.getElementsByTagName("body")[0];
      let bodyHeight = body.style.height;
      body.style.height="auto";

      wizardContainerElement.innerHTML = `<div id="initialCreateButtonClickSpinner"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>`;
      computeContainer.style.display="none";
      wizardContainerElement.style.display="block";

      this.processEvent(event);
      this.disableStartWizardButton();

      const product = this.getResourceType();
      const userProfile = await this.getDataService().user.profile();
      /* istanbul ignore next */
      const products = (userProfile && typeof userProfile.products === "object") ? userProfile.products : {};
      const hasCredential = await this.hasCredentials();
      if (!(product in products) || !hasCredential) {
        wizardContainerElement.style.display="none";
        wizardContainerElement.innerHTML = ``;
        computeContainer.style.display="block";
        body.style.height=bodyHeight;

        await this.showCredentialWarningDialog();
        this.enableStartWizardButton();
        return
      }

      const productInfo = await this.createProductInfoObject();

      let wizard = new CreateMATLABWizard({
        dataService: this.getDataService(),
        product: product,
        autoComplete: !(defaultValues && typeof defaultValues === 'object'),
        rulesManager: this.getRuleManager(),
        productInfo: productInfo // these are the rules IDs available to the user for this product
      });
      if (defaultValues && typeof defaultValues === 'object') {
        wizard.setDesiredRuleValues(defaultValues);
      }
      let throwArrayEl = document.createElement('div');
      throwArrayEl.className = "wizardWrapper";
      throwArrayEl.id = "wizardWrapper";
      wizardContainerElement.appendChild(throwArrayEl);
      wizard.setElement(throwArrayEl);
      document.body.style.height = "auto";
      wizard.render();
      this.enableStartWizardButton();
    }

    async createProductInfoObject () {
      const product = this.getResourceType();
      const productInfoObj = {
        ruleIdArray: [],
        credentialTypeIdMap: new Map()
      };
      let userPrefs;
      try {
        userPrefs = await this.getDataService().user.getPrefs();
      } catch (error) {} //user prefs can be empty

      try {
        const userProfile = await this.getDataService().user.profile();        
        const productRuleIds = userProfile.products[product];
        productInfoObj.ruleIdArray = productRuleIds;
        productInfoObj.credentialTypeIdMap = new Map();
        for (let ruleId of productRuleIds) {
          productInfoObj.credentialTypeIdMap.set(ruleId, userProfile.rules[ruleId]);
        }
        if (userPrefs) {
          const mostRecentKeys = Object.keys(userPrefs).filter(key => key.startsWith("mostrecent__"));
          mostRecentKeys.forEach(key => {productInfoObj[key] = userPrefs[key];});  
        }
      } catch (error) {
        Util.consoleLogError('createProductInfoObject', error);
      }
      return productInfoObj;
    }

    async hasCredentials (checkSubscriptions=true) {
      let credentialExists = false;
      try {
        credentialExists = await this.getRuleManager().hasCredentials("any", checkSubscriptions);
      } catch (error) {
        Util.consoleLogWarning("hasCredential", error);
      }
      return credentialExists;
    }

    goToResource (event, data) {
      if (data && typeof data === 'object' && ("path" in data)) {
        const path = data.path;
        $.event.trigger("changetoresourcepage:ccwa", {path: path, trigger: false, replace: false});
      }
    }

    /* istanbul ignore next */
    goToHome (event) {
      this.processEvent();
      $.event.trigger("changetoresourcepage:ccwa");
    }

    /* istanbul ignore next */
    goToCredential (event) {
      this.processEvent();
      $.event.trigger("changetocredentialpage:ccwa");
    }

    async showCredentialWarningDialog () {
      /* istanbul ignore next */
      const checkCredAndDirect = async function() {
        const hasCredential = await this.hasCredentials(); // Check it again when user click on "Get Started" in welcome screen, because CC1 cred sync might already populate the credentials while user is reading the welcome screen.
        if (hasCredential) {
          this.goToHome();
        } else {
          this.goToCredential();
        }
      }
      let warningDialog = new WarningDialog({
        hideIcon: true,
        title: I18NStringResource.warningTitleNeedCredential,
        text: I18NStringResource.warningTextNeedCredential,
        actionButtonLabel: I18NStringResource.warningActionLabelNeedCredential,
        closeButtonLabel: I18NStringResource.warningCloseLabel,
        modalContainerId: "modalContainer",
        classname: "noCredentialsWarning",
        actionFn: checkCredAndDirect.bind(this)
      });
      warningDialog.show();
    }

    enableStartWizardButton () {
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      let createButton = document.querySelector(startWizardButtonSelector);
      if (!createButton) { 
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          throw new Error(`Element not found: ${startWizardButtonSelector}`); 
        }
      } else {
        createButton.classList.remove("disabled");
        createButton.disabled = false;
      }
    }

    disableStartWizardButton () {
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      let createButton = document.querySelector(startWizardButtonSelector);
      if (!createButton) { throw new Error(`Element not found: ${startWizardButtonSelector}`); }
      createButton.disabled = true;
      createButton.classList.add("disabled");
    }

    getInitializeCacheDelay () {
      return 3000;
    }

    async initializeCache (attemptNumber, showAll = true) {
      Util.consoleLogTrace(`initializeCache`, `initializeCache with attempt ${attemptNumber} called.`);
      if (typeof this.getDataService().clear === 'function') {
        this.getDataService().clear("workflow/listConfigs");
      }
      if (isNaN(attemptNumber)) { attemptNumber = 0; }
      const ruleManager = this.getRuleManager();
      if (ruleManager && !ruleManager.isInitialized()) {
        try {
          await ruleManager.initialize(showAll);
        } catch (error) {
          ++attemptNumber;
          /* istanbul ignore next */
          Util.consoleLogWarning("initializeCache", `Failed to initialize rules: ${error.message}. ${ (attemptNumber < 2) ? "Retrying" : ""}`);
          if (attemptNumber < 2) {
            /* istanbul ignore next */
            setTimeout(async function () {
              await this.retryInitializeCache(attemptNumber);
            }.bind(this), this.getInitializeCacheDelay());
          } else {
            Util.consoleLogError('initializeCache', error);
            Util.notify('ERROR', I18NStringResource.dataServiceErrorServerError);
          }
        }
      }
    }

    async retryInitializeCache (attemptNumber) {
      Util.consoleLogTrace(`retryInitializeCache`, `retryInitializeCache called.`);
      await this.initializeCache(attemptNumber);
    }

  }

  return Collection;
});
