/*
 * Convert server JSON to usable entity
 */
define([
    "service/datatransform/uiElementUtil"
], function( UIElementUtil ) {

  class UISubElementInfo {

    constructor (data, settings = {}) {
      if (! (data && typeof data === 'object')) {
        throw new TypeError("Invalid input data argument");
      }

      const constraints = data.constraints || {};
      const config = data.config || {};
      const uiData = config.ui_element || {};
      let customData = uiData.custom_element || {};


      this.isCustomSubElement = true;
      this.attributeBaseName = customData.location_id || "";
      this.elementType = (UIElementUtil.shouldBeSelectElement(data.type, uiData.type) ? 'select' : uiData.type);
      if (config.hidden) {
        this.elementType = 'hidden';
      }
      this.id = data.id;
      this.defaultValue = config.default;
      if (["radio", "checkbox"].indexOf(this.elementType) >= 0) {
        if (uiData.value) {
          this.defaultValue = uiData.value;
        }
        if (uiData.prompt) {
          this.groupTitle = uiData.prompt;
        }
      }
      if ("allowed_values" in data && data.allowed_values) {
        this.allowedValues = data.allowed_values;
      }
      if (uiData.type === 'checkbox') {
        this.checkboxValues = [];
      }
      this.isOptional = !constraints.required;
      this.formSubmit = customData.form_submit;
      this.disabled = constraints.readonly;
      this.checked = Boolean(uiData.checked);
      this.dataQueryType = (UIElementUtil.QUERY_TYPES.includes(data.type) ? data.type : "");
      this.dependentIds = (config.child_param_ids && config.child_param_ids.length) ? config.child_param_ids : undefined;
      this.parentId = customData.parent_id;

      this.attributes = {
        id: data.id,
        name: data.id,
        placeholder: (uiData.prompt && !uiData.default) ? uiData.prompt : "",
        label: uiData.label || "",
        poptext: uiData.fineprint || "",
        tooltip: uiData.tooltip || ""
      };
      if ((this.elementType === 'checkbox' || this.elementType === 'radio') && this.checked) {
        this.attributes.checked = this.checked;
      }
      if (uiData.type === 'radio' && uiData.radio_group) {
        this.attributes.name = uiData.radio_group;
      }
      if (this.elementType === 'textarea' || this.elementType === 'select') {
        const attrName = this.elementType === 'select' ? "size" : "rows";
        if (uiData.multiple && this.elementType === 'select') {
          this.attributes.multiple = true;
        }
        let visibleLines = 1;
        if (uiData.lines && ! isNaN(uiData.lines)) {
          visibleLines = parseInt(uiData.lines);
        }
        this.attributes[attrName] = visibleLines;
      }

      this.validation = UIElementUtil.validationFromContraints(constraints);
      if (constraints.setting_include) {
        this.settingsIncludeKey = constraints.setting_include;
        if (uiData.type === 'checkbox') {
          for (const key in settings[this.settingsIncludeKey]) {
            this.checkboxValues.push(String(key));
          }
          if (this.checkboxValues.length !== 2) {
            throw new Error(`Invalid checkbox value set: name: ${this.settingsIncludeKey}, reason: number of properties != 2`);
          }
        }
      }
      if (constraints.setting_exclude) {
        this.settingsExcludeKey = constraints.setting_exclude;
      }
    }

    /*
      returns something like this:
      {
        "dataType": "UIElementInfo",
        "value": {
          "elementType": "text",
          "id": "CustomIamRole",
          "name": "CustomIamRole",
          "prompt": "",
          "label": "IAM Role",
          "finePrint": "Specify an IAM Role (optional) to associate with this instance.",
          "defaultValue": "",
          "value": "",
          "isOptional": true,
          "disabled": false,
          "checked": false,
          "dataQueryType": "",
          "validation": {}
        }
      }
    */
    toJSON () {
      let value = {};
      for(let i in this) {
        if(typeof this[i] !== 'undefined') {
          value[i] = this[i];
        }
      }
      return { dataType: "UISubElementInfo", value: value };
    }
  }

  return UISubElementInfo;
});
