define([

], function() {

    class UIElementUtil {

        static shouldBeSelectElement (dataType, uiElementType) {
            let isSelect = false;
            if (UIElementUtil.QUERY_TYPES.includes(dataType) &&
                (uiElementType === 'text' || uiElementType === 'select')) {
            isSelect = true;
            }
            return isSelect;
        }

        static validationFromContraints (constraints) {
            const validation = {};
            if (constraints.regex) {
                validation.regex = constraints.regex;
            } else if (constraints.min_value || constraints.max_value) {
                validation.range = {};
                if (constraints.min_value) { validation.range.min = constraints.min_value; }
                if (constraints.max_value) { validation.range.max = constraints.max_value; }
            }
            if (constraints.error && typeof constraints.error === 'string') {
                validation.errorMessage = constraints.error;
            }
            if (constraints.match_param_id) {
                validation.matchValueWith = constraints.match_param_id;
            }
            return validation;
        }

        static getPlatformFromQueryType (queryType) {
            let platform = "";
            if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
                const pieces = queryType.split('_');
                platform = pieces[0];
            }
            return platform;
        }

    } 

    Object.defineProperty(UIElementUtil,
        "QUERY_TYPES", {
        value: [
            "aws_ec2_network",
            "aws_ec2_subnet",
            "aws_ec2_instance_type",
            "aws_ec2_keypair",
            "aws_acm_certificate_arn",
            "aws_ec2_region",
            "azure_resource_group",
            "azure_vnet",
            "azure_network",
            "azure_subnet",
            "azure_vmsize",
            "azure_region",
            "aws_mwa_license_entitlement",
            "azure_mwa_license_entitlement"
        ]
    });

  return UIElementUtil;
});
