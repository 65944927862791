/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/inputPromptDialogTemplate",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(_, $, Bootstrap, InputPromptDialogTemplate, Util, I18NStringResource, DojoString) {

  class InputPromptDialog {

    constructor (args) {
      if (!this.validateArgs(args)) {
        throw new TypeError("Invalid args");
      }
      this.dialogTitle = args.dialogTitle;
      this.explanation = args.explanation;
      this.actionButtonLabel = args.actionButtonLabel;
      this.cancelButtonLabel = args.cancelButtonLabel;
      this.actionFn = args.actionFn;
      this.inputArray = args.inputArray;
      this.hiddenArray = args.hiddenArray;
      this.className = "";
      if (args.className && typeof args.className === 'string') {
        this.className = args.className;
      }
      this.dduxLogger = { logData: () => {} };
      if (args.dduxLogger && typeof args.dduxLogger === 'object' && args.dduxLogger.logData && typeof args.dduxLogger.logData === 'function') {
        this.dduxLogger = args.dduxLogger;
      }
    }

    getDialogElement () { return document.querySelector('div#inputPromptDialog'); }

    getActionButton () {
      let actionButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        actionButton = dialogEl.querySelector('button#actionButton');
      }
      return actionButton;
    }

    getCancelButton () {
      let cancelButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        cancelButton = dialogEl.querySelector('button#cancelButton');
      }
      return cancelButton;
    }

    getDDUXLogger () { return this.dduxLogger; }

    validateArgs (args) {
      let isValid = false;
      if (args && typeof args === 'object') {
          isValid = true;
      }
      return isValid;
    }

    getFieldValues () {
      const form = document.querySelector('form[name="inputPromptForm"]');
      let formData;
      if (form) {
        formData = new FormData(form);
        const keys = formData.keys();
        let key = keys.next();
        while (!key.done) {
          const value = formData.get(key.value);
          const escapedValue = _.escape(value);
          formData.set(key.value, escapedValue);
          key = keys.next();
        }
      }
      return formData;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    async doAction (event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const actionButton = this.getActionButton();
        const errorContainer = dialogEl.querySelector('div.errorTextContainer');
        const errorText = errorContainer.querySelector('p.errorText');
        try {
          actionButton.disabled = true;
          actionButton.classList.add("disabled");
          await this.actionFn(this.getFieldValues());
          const modal = Bootstrap.Modal.getInstance(dialogEl);
          if (modal) {
            modal.hide();
          }
          if (this.className) {
            $.event.trigger(`${this.className}_DoAction:ccwa`);
          }
        } catch (error) {
          if (errorContainer && errorContainer.style) {
            errorContainer.style.visibility = "visible";
          }
          if (typeof error === 'string') {
            errorText.textContent = error;
          } else {
            errorText.textContent = I18NStringResource.inputPromptDialogActionError;
          }
        } finally {
          actionButton.classList.remove("disabled");
          actionButton.disabled = false;
        }
      }
    }

    clearError () {
      const errorContainer = document.querySelector('div#inputPromptDialog div.errorTextContainer');
      if (errorContainer) {
        const errorText = errorContainer.querySelector('p.errorText');
        if (errorText) {
          errorText.innerHTML = "";
        }
        if (errorContainer.style) {
           errorContainer.style.visibility = 'hidden';
        }
      }
    }

    setFocusHandler (input) {
      input.addEventListener('focus', this.clearError.bind(this));
    }

    setup () {
      const dialogEl = document.querySelector('div#inputPromptDialog');
      if (dialogEl) {
        dialogEl.querySelectorAll('form input').forEach(this.setFocusHandler.bind(this));
        dialogEl.querySelectorAll('form textarea').forEach(this.setFocusHandler.bind(this));
        const actionButton = dialogEl.querySelector('button#actionButton');
        actionButton.addEventListener('click', async function() {await this.doAction();}.bind(this));
        dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
        const inputs = dialogEl.querySelectorAll('input:not([type="hidden"])');
        if (inputs && inputs.length > 0) {
          const firstInput = inputs[0];
          if (firstInput) {
            firstInput.focus();
          }
        }
      }
    }

    show () {
      const html = InputPromptDialogTemplate({
        dialogTitle: this.dialogTitle,
        explanation: this.explanation,
        actionButtonLabel: this.actionButtonLabel,
        cancelButtonLabel: this.cancelButtonLabel,
        hiddenArray: this.hiddenArray,
        inputArray: this.inputArray
      });
      const modalContainer = document.querySelector('#modalContainer');
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = document.querySelector('div#inputPromptDialog');
        if (dialogEl) {
          if (this.className) {
            dialogEl.classList.add(this.className);
          }
          dialogEl.addEventListener('shown.bs.modal', this.setup.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', function() {
            const dialogEl = document.querySelector('div#inputPromptDialog');
            if (dialogEl) {
              const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
              modal.dispose();
            }
          });
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }  
      }
    }

    onKeydown (event) {
      if (event) {
        const code = event.keyCode || event.which;
        if (code == 13) { // Enter key
          if (event.preventDefault) {
            event.preventDefault();
          }
          let focusedElement = document.activeElement;
          if (focusedElement && 
              focusedElement.tagName.toUpperCase() === "BUTTON" && (
                focusedElement.className === 'close'
                || focusedElement.id === "cancelButton" ||
                focusedElement.id === "actionButton"
              )
          ) {
            focusedElement.click();
          }
        }
      }
    }

  }

  return InputPromptDialog;
});
