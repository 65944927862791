/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "supportedProducts",
  "util",
  "config",
  "computeResource/collections/collection",
  "computeResource/collections/cluster/clusterCollection",
  "computeResource/collections/matlab/matlabCollection",
  "computeResource/collections/onlineServer/onlineServerCollection",
  "computeResource/collections/parallelServer/parallelServerCollection",
  "computeResource/collections/productionServer/productionServerCollection",
  "computeResource/collections/webAppServer/webAppServerCollection"  ,
  "computeResource/collections/licenseManager/licenseManagerCollection",
  "computeResource/collections/cfeDemo/cfeDemoCollection",
  "computeResource/collections/cloudNetwork/cloudNetworkCollection"
], function (
  _,
  $,
  SupportedProducts,
  Util,
  Config,
  Collection,
  ClusterCollection,
  MatlabCollection,
  OnlineServerCollection,
  ParallelServerCollection,
  ProductionServerCollection,
  WebAppServerCollection,
  LicenseManagerCollection,
  CFEDemoCollection,
  CloudNetworkCollection
) {

  const _amdClosureArgs = {
    "MatlabCollection": MatlabCollection,
    "ClusterCollection": ClusterCollection,
    "OnlineServerCollection": OnlineServerCollection,
    "ParallelServerCollection": ParallelServerCollection,
    "ProductionServerCollection": ProductionServerCollection,
    "WebAppServerCollection": WebAppServerCollection,
    "LicenseManagerCollection": LicenseManagerCollection,
    "CfeDemoCollection": CFEDemoCollection,
    "CloudNetworkCollection": CloudNetworkCollection
  };

  class CollectionFactory {

    // class static methods
    static initializeCollectionTemplates () {
      const productTemplates = {};
      const products = SupportedProducts.getProductNameList();
      for (let product of products) {
        const camelCaseProduct = Util.underscoreToTitleCase(product, false);
        const collectionClass = `${camelCaseProduct}Collection`;
        const productCollectionConstructor = _amdClosureArgs[collectionClass];
        if (!productCollectionConstructor) {
          throw new TypeError(`No class constructor method found for product ${product}`);
        }
        productTemplates[product] = productCollectionConstructor;
      }
      return productTemplates;
    }

    static getCollectionClass (product) {
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError("Invalid product argument");
      }
      const clazz = CollectionFactory.COLLECTION_PRODUCT_TEMPLATES[product];
      return clazz;
    }

    static isValidCollection (item) {
      let isValid = false;
      if (item && typeof item === 'object' && item instanceof Collection) {
        isValid = true;
      }
      return isValid;
    }

    static initializeProductCollectionsForUser (profile, dataService) {
      if (!dataService || !Util.dataServiceIsValid(dataService)) {
        throw new TypeError("Invalid dataService argument");
      }
      CollectionFactory._PRODUCT_COLLECTIONS.clear();
      let userProducts = [];
      if (profile && profile.products) {
        userProducts = Object.keys(profile.products);
      }
      const productList = SupportedProducts.getProductNameList();
      for (let product of productList) {
        if (product === 'matlab' || product === 'cluster' || userProducts.includes(product)) {
          const collectionClass = CollectionFactory.getCollectionClass(product);
          const collection = new collectionClass({
            resourceType: product,
            selector: `ol#${product}-resource-collection.collection.collection-container`,
            dataService: dataService,
            proxyURL: Config.getProxyURL()
          });
          CollectionFactory._PRODUCT_COLLECTIONS.set(product, collection);
        }
      }
      CollectionFactory._PRODUCT_COLLECTIONS_INITIALIZED = true;
    }

    static getProductCollection (product) {
      if (!CollectionFactory._PRODUCT_COLLECTIONS_INITIALIZED) {
        throw new Error("CollectionFactory._PRODUCT_COLLECTIONS not yet initialized");
      }
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError("Invalid product argument");
      }
      const collection = CollectionFactory._PRODUCT_COLLECTIONS.get(product);
      return collection;
    }

    static getUserSupportedCollectionsIterator () {
      if (!CollectionFactory._PRODUCT_COLLECTIONS_INITIALIZED) {
        throw new Error("CollectionFactory._PRODUCT_COLLECTIONS not yet initialized");
      }
      return CollectionFactory._PRODUCT_COLLECTIONS.keys();
    }

  }
  // class static members
  Object.defineProperty(CollectionFactory, "COLLECTION_PRODUCT_TEMPLATES", {
    value: CollectionFactory.initializeCollectionTemplates(),
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(CollectionFactory, "_PRODUCT_COLLECTIONS", {
    value: new Map(),
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(CollectionFactory, "_PRODUCT_COLLECTIONS_INITIALIZED", {
    value: false,
    writable: true,
    configurable: true,
    enumerable: false
  });

  return CollectionFactory;
});
