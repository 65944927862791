/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "util"
], function (_, $, Backbone, Util) {

  class AbstractWizardStep extends Backbone.View {

    constructor (params) {
      super();
      this.events = {};
      this.template = undefined;
      if(this.constructor === AbstractWizardStep) {
        throw new Error("Cannot instantiate abstract class.");
      } else {
        this.initializeParams(params);
      }
    }

    initializeParams (params) {
      if (!params || typeof params !== "object" || Object.keys(params).length === 0) {
        throw new TypeError("Invalid initialize params argument");
      }
      if (!params.wizardStepName || typeof params.wizardStepName !== 'string') {
        throw new TypeError("Invalid wizardStepName param value");
      }
      this.wizardStepName = params.wizardStepName;
      if (! Util.dataServiceIsValid(params.dataService)) {
        throw new TypeError("Invalid dataService params value");
      }
      this.dataService = params.dataService;
      if (!params.productName || typeof params.productName !== 'string') {
        throw new TypeError("Invalid productName params value");
      }
      this.productName = params.productName;
      this.autoComplete = params.hasOwnProperty("autoComplete") ? params.autoComplete : false;
      this.configInfoSummary = null; // default
      if (Util.configInfoSummaryIsValid(params.configInfoSummary)) {
        this.configInfoSummary = params.configInfoSummary;
      }
      this.firstInputOnFormSelector = "";
      if (!params.wrapperElementClassName || typeof params.wrapperElementClassName !== 'string') {
        throw new TypeError("Invalid wrapperElementClassName params value");
      }
      this.validFieldHandler = () => {};
      if (params.validFieldHandler && typeof params.validFieldHandler === 'function') {
        this.validFieldHandler = params.validFieldHandler;
      }
      /* istanbul ignore next */
      this.invalidFieldHandler = () => {};
      if (params.invalidFieldHandler && typeof params.invalidFieldHandler === 'function') {
        this.invalidFieldHandler = params.invalidFieldHandler;
      }
      this.setElement(Util.createWizardStepDisposableElement(params.wrapperElementClassName));
      this.desiredStepValues = {};
    }

    getDataService () { return this.dataService; }
    onValidField () { return this.validFieldHandler(); }
    onInvalidField () { return this.invalidFieldHandler(); }

    getStepName () { return this.wizardStepName; }

    setDesiredStepValues (values) { this.desiredStepValues = values; }
    getDesiredStepValues () { return this.desiredStepValues; }

    setFirstInputOnFormSelector (sel) { this.firstInputOnFormSelector = sel; }
    getFirstInputOnFormSelector () { return this.firstInputOnFormSelector; }

    getProductName () { return this.productName; }

    isAutoCompleteOn () { return this.autoComplete; }

    setAutoComplete (autoCompleteIsOn) { this.autoComplete = (autoCompleteIsOn === true); }

    getConfigInfoSummary () { return this.configInfoSummary; }

    resetConfigSummary () {
      this.getConfigInfoSummary().clear();
    }

    getFirstInputOnForm () {
      let firstInput;
      if (this.getFirstInputOnFormSelector()) {
        firstInput = this.el.querySelector(this.getFirstInputOnFormSelector());
      }
      return firstInput;
    }

    triggerAutoCompleteErrorEvent (error) {
      if (this.isAutoCompleteOn()) {
        $.event.trigger("wizardsteperror:ccwa", { step: this.getStepName(), error: error });
      }
    }

    mergeDynamicEvents(addOn) {
      if (addOn && typeof addOn === 'object') {
        let keys = Object.keys(addOn);
        for (let key of keys) {
          this.events[key] = addOn[key];
        }
      }
    }

    async renderStep () {
      this.render();
      // async method
      await this.initializeStep();
    }

    async render () {
      Util.hidePopovers();
    }

    async initializeStep () {

    }

  }

  return AbstractWizardStep;
});
