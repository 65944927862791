/* jshint esversion: 8 */
define([
    "underscore",
    "jquery",
    "util",
    "constants",
    "service/platform/aws/awsDataService",
    "service/platform/azure/azureDataService",
    "service/general/loggingService",
    "service/general/cloudService",
    "service/general/userService",
    "service/general/sharingService",
    "service/general/adminService",
    "service/general/legacyParallelClusterService",
    "service/general/legacyParallelCredentialService",
    "service/general/legacyParallelGlobalService",
    "service/general/workflowService",
    "service/datatransform/ruleInfo",
    "service/datatransform/dependentOptionInfo",
    "service/datatransform/queryDependentOptionDataArgument",
    "service/datatransform/uiElementUtil",
    "service/datatransform/dataTransformationUtil",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string"
], function( _,
             $,
             Util,
             CCWA_Constants,
             AWSDataService,
             AzureDataService,
             LoggingService,
             CloudService,
             UserService,
             SharingService,
             AdminService,
             LegacyParallelClusterService,
             LegacyParallelCredentialService,
             LegacyParallelGlobalService,
             WorkflowService,
             RuleInfo,
             DependentOptionInfo,
             QueryDependentOptionDataArgument,
             UIElementUtil,
             DataTransformationUtil,
             I18NStringResource,
             DojoString
) {

  const PLATFORM_SERVICES = new Map();

  /**
   * CloudCenterDataService handles all interaction with Cloud Center server via DAO.
   */
  class CloudCenterDataService {

    constructor (options) {
      if (!options || typeof options !== "object" || !options.dao) {
        throw new TypeError("Invalid options argument");
      }
      let context = this;
      this.dao = options.dao;
      // platform-specific services
      this.awsplatform = new AWSDataService({dao: this.dao});
      this.azureplatform = new AzureDataService({dao: this.dao});
      PLATFORM_SERVICES.set('aws', this.awsplatform);
      PLATFORM_SERVICES.set('azure', this.azureplatform);
      // general services
      this.logging = new LoggingService();
      this.workflow = new WorkflowService({dao: this.dao});
      this.cloud = new CloudService({dao: this.dao});
      this.user = new UserService({dao: this.dao});
      this.sharing = new SharingService({dao: this.dao});
      this.admin = new AdminService({dao: this.dao});
      this.legacy_parallel = {
        cluster: new LegacyParallelClusterService({dao: this.dao}),
        credential: new LegacyParallelCredentialService({dao: this.dao}),
        global: new LegacyParallelGlobalService({dao: this.dao}),
        getClusterService: function () { return this.cluster; },
        getCredentialService: function () { return this.credential; },
        getGlobalService: function () { return this.global; }
      };
      this.abortOutstandingRequests = () => {
        if (Util.fetchAbortSupported()) {
          this.getDAO().abortAllFetchCalls();
        }
      };

      /*
       * ui translates from raw server JSON to client-expected data types.
       * Restricts knowledge of server to the service layer.
       */
      this.ui = {
        getParentService () { return context; },

        async externalIp () {
          const externalIPv4Key = "externalIPv4";
          let returnValue = "";
          if (!context.externalIp) {
            context.externalIp = sessionStorage.getItem(externalIPv4Key);
          }
          if (!context.externalIp) {
              let response = null;
              try {
                response = await context.getUserService().loggedIn();
              } catch (error) {
                Util.consoleLogWarning('externalIp', error);
              }
              if (response) {
                context.externalIp = response.external_cidr;
                sessionStorage.setItem(externalIPv4Key, context.externalIp);
              }
          }
          if (context.externalIp) {
           returnValue = context.externalIp;
          }
          return returnValue;
        },

        async getLatestConfigData (configId) {
          Util.consoleLogTrace("ui.getLatestConfigData", "getting server data");
          if (!Util.isMD5(configId)) {
            throw new TypeError("Invalid configId argument");
          }
          let config;
          let rawData = await this.getParentService().getWorkflowService().listConfig(configId);
          if (rawData && Array.isArray(rawData) && rawData.length === 1) {
            config = rawData[0];
          }
          return config;
        },

        async renderCredentialList (platform, credentialId, popText, dispDescription, data) {
          Util.consoleLogTrace("ui.renderCredentialList", "getting server data");
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          if (!Util.isMD5(credentialId)) {
            throw new TypeError("Invalid credentialId argument");
          }
          return await platformDataService.renderCredentialList(credentialId, popText, dispDescription, data);
        },

        async listCredentials (platform, credentialTypeId, includeSubscriptions = true) {
          Util.consoleLogTrace("ui.listCredentials", "getting server data");
          let credentialInfoArray = [];
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          if (!Util.isMD5(credentialTypeId)) {
            throw new TypeError("Invalid credentialTypeId argument");
          }
          const rawData = await platformDataService.listCredentials(credentialTypeId, includeSubscriptions);
          if (rawData && typeof rawData === 'object') {
            credentialInfoArray = DataTransformationUtil.getCredentialInfoArray(platform, credentialTypeId, rawData);
          } else {
            throw new Error(I18NStringResource.dataServiceErrorNoResults);
          }
          return credentialInfoArray;
        },

        async getCredential (platform, credentialId) {
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          if (!Util.isMD5(credentialId)) {
            throw new TypeError("Invalid credentialId argument");
          }
          return await platformDataService.getCredential(credentialId);
        },

        async createCredential (platform, createCredArgs) {
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          if (!createCredArgs || typeof createCredArgs !== 'object') {
            throw new TypeError("Invalid createCredArgs argument");
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          return await platformDataService.createCredential(createCredArgs);
        },

        async importCredential (platform, importCredArgs) {
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          if (!importCredArgs || typeof importCredArgs !== 'object') {
            throw new TypeError("Invalid importCredArgs argument");
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          return await platformDataService.importCredential(importCredArgs);
        },

        async deleteCredential (credentialId, credentialTypeId) {
          if (!Util.isMD5(credentialId)) {
            throw new TypeError("Invalid credentialId argument");
          }
          if (!Util.isMD5(credentialTypeId)) {
            throw new TypeError("Invalid credentialTypeId argument");
          }
          const platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
          if (platform === CCWA_Constants.UNKNOWN_CREDENTIAL_TYPE_ID || !PLATFORM_SERVICES.has(platform)) {
            Util.notify("ERROR", I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
            return;
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          // See if credential is in use by CC2 resources and fail if it is
          const configs = await this.getParentService().getWorkflowService().list("config", `mw-name,mw-credential-id=${credentialId}`, undefined);
          let names = await Util.collectExistingConfigNames(configs)
          if (names) {
            throw new Error(DojoString.substitute(I18NStringResource.credentialsPageUnableToDelete, [names]));
          }
          // next, try CC1
          try {
            //always try to delete legacy cred
            await this.getParentService().getLegacy_parallelService().getCredentialService().delete();
          } catch (error) {
            //if cannot delete, that's OK ... sync will handle
            Util.consoleLogError("Calling CC1 delete credential", error);
          }
          // Made it to here, do credential delete depending on what credential this is
          try {
            await platformDataService.deleteCredential(credentialId, credentialTypeId, configs);
          } catch (error) {
            Util.consoleLogError('deleteCredential', error);
            Util.notify("ERROR", DojoString.substitute(I18NStringResource.credentialsPageUnableToDelete, [error.message]));
          }
        },

        async getCredentialDescription (platform, credentialId) {
          if (!platform || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError("Invalid platform argument");
          }
          if (!Util.isMD5(credentialId)) {
            throw new TypeError("Invalid credentialId argument");
          }
          const platformToDescriptionFieldName = {
            azure: 'description',
            aws: 'Description'
          };
          const rawData = await this.getCredential(platform, credentialId);
          let text = "";
          const fieldName = platformToDescriptionFieldName[platform];
          if (fieldName && (fieldName in rawData)) {
            text = rawData[fieldName].trim();
            if (rawData.Tags && typeof rawData.Tags === 'object' && ("Account" in rawData.Tags)) {
              let acct = rawData.Tags.Account.trim();
              if (text && acct) {
                return `${text} (${acct})`;
              } else if (acct) {
                return acct;
              } else {
                return text;
              }
            }
          }
          return text;
        },

        async getConfigDetailData (configId) {
          Util.consoleLogTrace("ui.getConfigDetailData", "called");
          if (!Util.isMD5(configId)) {
            throw new TypeError("Invalid configId argument");
          }
          let consolidatedData = {};
          let configDetailData;
          let credentialTypeId;
          try {
            configDetailData = await this.getParentService().getWorkflowService().list("config", null, {config_id:configId, refresh:true});
          } catch (error) {
            Util.consoleLogError("ui.getConfigDetailData", error);
            throw new Error("Unable to get config detail data.");
          } finally {
            if (configDetailData && typeof configDetailData === 'object' &&
                Array.isArray(configDetailData) && configDetailData.length === 1) {
              configDetailData = configDetailData[0];
              if (typeof configDetailData === 'object' && configDetailData.params &&
                  typeof configDetailData.params === 'object') {
                credentialTypeId = Object.keys(configDetailData.params.credential_type)[0];
                Util.copyProperties(configDetailData.params, consolidatedData, false, false);
                consolidatedData.cloudData = configDetailData.cloud;
                if (configDetailData.cloud_machines) {
                  consolidatedData.machineStatusData = configDetailData.cloud_machines[0];
                  consolidatedData.allMachinesData = configDetailData.cloud_machines;
                }
                if (configDetailData.cloud_access) {
                  consolidatedData.accessData = configDetailData.cloud_access;
                }
                if (configDetailData.cloud_events) {
                  consolidatedData.eventsData = configDetailData.cloud_events;
                }
                if (configDetailData.cloud_links) {
                  consolidatedData.linksData = configDetailData.cloud_links;
                }
                if (configDetailData.eol) {
                  consolidatedData.eol = configDetailData.eol;
                }
                consolidatedData.RuleId = configDetailData.rules_id;
              }
            }
          }
          return consolidatedData;
        },

        async listSection2UIElements (section1RuleData, credentialId) {
          Util.consoleLogTrace("ui.listSection2UIElements", "called");
          if (! (section1RuleData && typeof section1RuleData === 'object')) {
            throw new TypeError("Invalid section1RuleData argument");
          }
          if (section1RuleData.id) {
            return this.listSection2UIElementsByRuleId(section1RuleData.id, credentialId, section1RuleData.cloudLocations);
          }
          let section1Facets = RuleInfo.convertToNative(section1RuleData);
          try {
            let bodies = await this.getParentService().getWorkflowService().getBody("rules", section1Facets);
            if (bodies && bodies.length === 1) {
              let uiSections = [];
              let settings = [];
              let configData = bodies[0];
              if (configData && DataTransformationUtil.configDataIsValid(configData)) {
                uiSections = DataTransformationUtil.sortUIRulesBySections(configData);
                settings = DataTransformationUtil.getSettingsInfoFromRules(configData);
              } else if (configData && ! DataTransformationUtil.configDataIsValid(configData)){
                return Promise.reject(new Error(I18NStringResource.dataServiceErrorInvalidJson));
              }
              return {uiSections: uiSections, settingsMap: settings};
            }
            if (bodies.length > 1) {
              return Promise.reject(new Error(I18NStringResource.dataServiceErrorMultiResults));
            }
            return Promise.reject(new Error(I18NStringResource.dataServiceErrorNoResults));

          } catch(error) {
            throw new Error(DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]));
          }

        },

        async listSection2UIElementsByRuleId (ruleId, credentialId, location, desiredValues = null) {
          Util.consoleLogTrace("ui.listSection2UIElementsByRuleId", "called");
          if (! (ruleId && typeof ruleId === 'string')) {
            throw new TypeError("Invalid ruleId argument");
          }
          try {
            // (rulesId, location, showAll=true, skipLookups=false, reduceSettings=false)
            // const showAll=true;
            // const skipLookups=true;
            // const reduceSettings=false;
            // let ruleDetails = await this.getParentService().getWorkflowService().getRuleById(ruleId, location, showAll, skipLookups, reduceSettings);
            let ruleDetails = await this.getParentService().getWorkflowService().details(ruleId, credentialId, location);
            if (ruleDetails && Array.isArray(ruleDetails) && ruleDetails.length === 1) {
              let uiSections = [];
              let settings = [];
              let configData = ruleDetails[0];
              if (configData && DataTransformationUtil.configDataIsValid(configData)) {
                uiSections = DataTransformationUtil.sortUIRulesBySections(configData, desiredValues);
                settings = DataTransformationUtil.getSettingsInfoFromRules(configData);
              } else {
                if (configData && ! DataTransformationUtil.configDataIsValid(configData)) {
                  return Promise.reject(new Error(I18NStringResource.dataServiceErrorInvalidJson));
                }
              }
              return {uiSections: uiSections, settingsMap: settings};
            } else {
              return Promise.reject(new Error(I18NStringResource.dataServiceErrorNoResults));
            }
          } catch(error) {
            throw new Error(DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]));
          }
        },

        async getRulesArrayByProduct (product, rulesID, credTypeID, saveAll = false, showAll = true) {
          Util.consoleLogTrace("ui.getRulesArrayByProduct", "called");
          let facets = {};
          if (!saveAll) {
            if (showAll) {
                facets = {
                  product: product,
                };
            } else {
              facets = {
                cloud_location: "",
                credential_type: "",
                cloud_network_type: "",
                description: "",
                license_type: "",
                operating_system: "",
                product: product,
                version: "",
                cloud_provider: "",
                highlighted_features: ""
              };
            }
          }
          let additionalSearch={show_all: showAll, enrich_entitlements:true}; //rrs requires body
          if (rulesID) {
            additionalSearch.rules_id = rulesID;
          }
          if (credTypeID) {
            additionalSearch.credential_type_id = credTypeID;
          }
          if (!rulesID && !credTypeID && showAll) {
            additionalSearch.skip_enrichment_defaults = true;
            additionalSearch.skip_enrichment_cloud = true;
            additionalSearch.reduce_rules_settings = true;
          }
          let rawRules = [];
          let errorMessage = "";
          try {
            rawRules = await this.getParentService().getWorkflowService().search("rules", facets, additionalSearch);
          } catch (error) {
            errorMessage = DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]);
          }
          if ((!rawRules || !rawRules.length) && !errorMessage) {
            errorMessage = `${I18NStringResource.dataServiceErrorUnableToGetData}`;
          }
          if (errorMessage && (!rawRules || !rawRules.length)) {
            throw new Error(errorMessage);
          }
          return DataTransformationUtil.getRuleInfoArrayFromRules(rawRules, saveAll);
        },

        async queryDependentOptionData (dependentOptionInfo, args) {
          Util.consoleLogTrace("ui.queryDependentOptionData", "called");
          if (!(dependentOptionInfo instanceof DependentOptionInfo)) {
            throw new TypeError("Invalid dependentOptionInfo argument");
          }
          if ( !(args && typeof args === 'object' && args instanceof QueryDependentOptionDataArgument)) {
            throw new TypeError("Invalid args argument");
          }

          const queryType = dependentOptionInfo.queryType;
          const credId = args.credentialId;
          const options = {
            location: args.cloudLocation,
            supportPrivateSubnets: args.supportPrivateSubnets,
            vpc: args.vpc,
            subnet: args.subnet,
            product: args.product,
            release: args.release
          };

          let results = [];
          if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
            const platform = UIElementUtil.getPlatformFromQueryType(queryType);
            const platformDataService = PLATFORM_SERVICES.get(platform);
            const optionFields = await platformDataService.getDependentOptionFields(queryType, options);
            let optionData = await platformDataService.getDependentOptionData(queryType, credId, options);
            if (optionData && optionData.msg) {
              optionData = optionData.msg;
              results = Util.extractOptionTextFromObjectArray(optionData, optionFields, queryType);
            }
          }
          return results;
        },

        async updateCredentialDescription (credentialId, credentialTypeId, description) {
          Util.consoleLogTrace("ui.updateCredentialDescription", "called");
          if (!Util.isMD5(credentialId)) {
            throw new TypeError("Invalid credentialId argument");
          }
          if (!Util.isMD5(credentialTypeId)) {
              throw new TypeError("Invalid credentialTypeId argument");
          }
          if (typeof description !== 'string') {
              throw new TypeError("Invalid description argument");
          }
          const platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
          if (platform === CCWA_Constants.UNKNOWN_CREDENTIAL_TYPE_ID || !PLATFORM_SERVICES.has(platform)) {
            throw new Error(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          await platformDataService.updateCredentialDescription(credentialId, credentialTypeId, description);
        },

        async getAccountIdFromConfig (config) {
          let accountId = "";
          if (config && typeof config === 'object') {
            if (config.params && config.params.cloud_provider) {
              const platform = config.params.cloud_provider;
              if (!PLATFORM_SERVICES.has(platform)) {
                throw new Error(`Platform not supported: ${platform}`);
              }
              const platformDataService = PLATFORM_SERVICES.get(platform);
              accountId = await platformDataService.getAccountIdFromConfig(config);
            }
          }
          return accountId;
        },

        async getCredentialDataFromAccountId (accountId, credentialTypeId) {
          let credentialData = {};
          if (!accountId || typeof accountId !== 'string') {
            throw new TypeError("Invalid accountId argument");
          }
          if (!credentialTypeId || typeof credentialTypeId !== 'string') {
            throw new TypeError("Invalid credentialTypeId argument");
          }
          const platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
          if (!platform || typeof platform !== 'string' || !PLATFORM_SERVICES.has(platform)) {
            throw new TypeError(`Unsupported platform ${platform}`);
          }
          const platformDataService = PLATFORM_SERVICES.get(platform);
          try {
            const userCredentials = await this.getParentService().getAdminService().listAllUserCreds();
            credentialData = await platformDataService.getCredentialDataFromAccountId(accountId, credentialTypeId, userCredentials);
          } catch (error) {
            Util.consoleLogError('getCredentialDataFromAccountId', error);
          }
          return credentialData;
        }

      };

      this.getLoggingService().initialize();

      _.bindAll(this, "useHtmlLocalStorage", "getCurrentUserData", "unsetCurrentUserData", "setOriginId", "getOriginId");
    }

    /*
    ** Getters and Setters
    */
    getDAO () { return this.dao; }
    useHtmlLocalStorage () { return this.getDAO().useHtmlLocalStorage(); }
    getCurrentUserData  () { return this.getDAO().getLoginData(); }
    unsetCurrentUserData () { this.getDAO().setLoginData(null); }
    setOriginId (originId) { this.getDAO().setOriginId(originId); }
    getOriginId () { return this.getDAO().getOriginId(); }
    // do nothing here; overridden by cached data service to clear caches
    clear () {}
    clearAll () {}
    init () {}
    getUiService () { return this.ui; }
    getLoggingService () { return this.logging; }
    getCloudService () { return this.cloud; }
    getSharingService () { return this.sharing; }
    getUserService () { return this.user; }
    getAdminService () { return this.admin; }
    getWorkflowService () { return this.workflow; }
    getLegacy_parallelService () { return this.legacy_parallel; }
    getPlatformDataService (platform) {
      if (!platform || !PLATFORM_SERVICES.has(platform)) {
        throw new TypeError(`Unrecognized platform ${platform}`);
      }
      return PLATFORM_SERVICES.get(platform);
    }
  }

  return CloudCenterDataService;
});
