/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/wizardSelectCredTypeDialog",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(_, $, Bootstrap, SelectCredentialTypeDialogTemplate, Util, I18NStringResource, DojoString) {

  class SelectCredentialTypeDialog {

    constructor () {
      this.modalContainerId = "#modalContainer";
    }

    getModalContainer () { return document.querySelector(this.modalContainerId); }

    getDialogElement () { return document.querySelector('div#wizardCredTypeDialog'); }

    getCancelButton () {
      let cancelButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        cancelButton = dialogEl.querySelector('#credTypeCancelWizardButton');
      }
      return cancelButton;
    }

    getImportCredButton () {
      let importButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        importButton = dialogEl.querySelector('a#importAWSRoleCredential');
      }
      return importButton;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    show () {
      const templateParams = {
        wizardCredTypeHeader: I18NStringResource.credentialsPageAddCredentialDialogTitle,
        createAzureComingSoon: I18NStringResource.credentialsPageCreateAzureComingSoonText,
        credTypeHeading: I18NStringResource.credentialsPageCredTypeHeading,
        createAzureCredential: I18NStringResource.credentialsPageCreateAzureCredentialText,
        createAzureRefreshTokenCredential: I18NStringResource.credentialsPageCreateAzureRefreshTokenCredentialText,
        createAWSCredential: I18NStringResource.credentialsPageCreateAWSCredentialButtonText,
        createAWSRoleCredential: I18NStringResource.credentialsPageCreateAWSRoleCredentialText,
        importAWSRoleCredential: I18NStringResource.credentialsPageImportAWSRoleCredentialText,
        AddWizardButtonLabel: I18NStringResource.credentialsPageAddButtonLabel,
        cancelWizardButtonLabel: I18NStringResource.credentialsPageCloseButtonLabel,
        azureSupported: Util.isPlatformSupported("azure")
      };
      const html = SelectCredentialTypeDialogTemplate(templateParams);
      const modalContainer = this.getModalContainer();
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          dialogEl.addEventListener('shown.bs.modal', function() {
            const getImportCredButton = this.getImportCredButton();
            if (getImportCredButton) {
              getImportCredButton.focus();
            }
          }.bind(this), false);
          dialogEl.addEventListener('hidden.bs.modal', function() {
            const dialogEl = this.getDialogElement();
            if (dialogEl) {
              const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
              modal.dispose();
            }
          }.bind(this), false);
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }
      }
    }

    onKeydown (event) {
      if (event) {
        const code = event.keyCode || event.which;
        if (code == 13) { // Enter key
          if (event.preventDefault) {
            event.preventDefault();
          }
          let focusedElement = document.activeElement;
          if (focusedElement && 
              focusedElement.tagName.toUpperCase() === "BUTTON" && (
                focusedElement.className === 'close'
                || focusedElement.id === "credTypeCancelWizardButton"
              )
          ) {
            focusedElement.click();
          } else if (focusedElement.tagName.toUpperCase() === 'A') {
            focusedElement.click();
          }
        }
      }
    }

  }

  return SelectCredentialTypeDialog;
});
