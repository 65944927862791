/* jshint esversion: 8 */
define([
  "service/datatransform/uiElementInfo"
], function(UIElementInfo) {

  class DependentOptionInfo {

    constructor (elementInfo) {
      this.id = elementInfo.id;
      this.type = elementInfo.elementType;
      this.queryType = elementInfo.dataQueryType;
      this.suggestedDefault = elementInfo.defaultValue;
      this.dependentIds = elementInfo.dependentIds;
      this.isDependentElement = false;
      this.settingsIncludeKey = elementInfo.settingsIncludeKey;
      this.settingsExcludeKey = elementInfo.settingsExcludeKey;
    }

    setId (id) { this.id = id; }
    getId () { return this.id; }
    setType (type) { this.type = type; }
    getType () { return this.type; }
    setQueryType (queryType) { this.queryType = queryType; }
    getQueryType () { return this.queryType; }
    getSuggestedDefault () { return this.suggestedDefault; }
    setSuggestedDefault (defaultValue) { this.suggestedDefault = defaultValue; }
    setDependentIds (idArray) {
      if (idArray && !Array.isArray(idArray)) {
        throw new TypeError("Invalid idArray argument");
      }
      this.dependentIds = idArray;
    }
    getDependentIds () { return this.dependentIds; }
    setDependent (isDependent) { this.isDependentElement = (isDependent === true); }
    isDependent () { return this.isDependentElement; }
    get selector () { return `${this.type}#${this.id}`; }
    getSettingsIncludeKey () { return this.settingsIncludeKey; }
    getSettingsExcludeKey () { return this.settingsExcludeKey; }

    // Needed for creating unique cache hashID
    toString () {
      return `id:${this.id},type:${this.type},queryType:${this.queryType},isDependentElement:${this.isDependentElement},dependentIds:${this.dependentIds}`;
    }
  }

  return DependentOptionInfo;
});
