/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "validation/inlineComponentValidation",
    "components/inlinePopover",
    "components/createNewSSLCertificateDialog",
    "dojo/i18n!nls/cloudCenterStringResource",
    "util",
    "dialogs/createNewItemDialog",
    "config",
    "service/cloudCenterDataService", 
    "dao/cloudCenterDao"
], function(
    Lit,
    CloudCenterElement,
    InlineValidation,
    InlinePopover,
    CreateNewSSLCertificateDialog,
    I18NStringResource,
    Util,
    CreateNewItemDialog,
    GCONFIG,
    CloudCenterDataService,
    CloudCenterDao
) {

    class AutoLogin extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('autologin`, `cceInitialize invoked');
            // Default values
            this.entitlementid = "";
            this.entitlementlabel = "";
            this.entitlementpoptext = "";
            this.entitlementvalue = "";
            this.autologinid = "";
            this.autologinlabel = "";
            this.autologinpoptext = "";
            this.autologinvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                entitlementid: {type: String},
                entitlementlabel: {type: String},
                entitlementpoptext: {type: String},
                entitlementvalue: {type: String},
                autologinid: {type: String},
                autologinlabel: {type: String},
                autologinpoptext: {type: String},
                autologinvalue: {type: String}
            };
        }

        firstUpdated () {
            this.updateUI()
        }

        _onChange(event) {
            this.updateUI()
        }

        updateUI() {
            const entitlementInput = this.renderRoot.querySelector(`select#${this.entitlementid}`);
            const primaryParam = this.renderRoot.querySelector(`input#${this.autologinid}`);
            const disabled = this.renderRoot.querySelector('input:checked').attributes.option.value === "No";

            if (disabled) {
                entitlementInput.dispatchEvent(new Event("focus"));
                entitlementInput.disabled = true;
                entitlementInput.classList.add('disabled');
                primaryParam.value = "No";
            } else {
                entitlementInput.disabled = false;
                entitlementInput.classList.remove('disabled');
                entitlementInput.focus();
                primaryParam.value = "Yes";
            }
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="section2InputContainer">
                    <label class="paramLabel" for="${this.autologinid}">${this.autologinlabel}</label>
                    <div class="autoLoginRadioButtonLayout section2InputValidationContainer">
                        <div class="radioButtonContainer">
                            <input type="radio" name="${this.autologinid}" option="Yes" value="Yes" ?checked=${this.autologinvalue === "Yes"} id="${this.autologinid}" @change=${this._onChange}/>
                            <label for="${'enable' + this.timestamp}">Enable</label>
                        </div>
                        <div class="radioButtonContainer">
                            <input type="radio" name="${this.autologinid}" option="No" value="No" ?checked=${this.autologinvalue === "No"} id="disable${this.autologinid}" @change=${this._onChange}/>
                            <label for="${'disable' + this.timestamp}">Disable</label>
                        </div>
                    </div>
                </div>

                <div class="section2InputContainer">
                    <label class="paramLabel" for="${this.entitlementid}"></label>
                    <div class="section2InputValidationContainer">
                        <select name="${this.entitlementid}" id="${this.entitlementid}" >
                            <option value="" disabled hidden ?selected=${!this.entitlementvalue}></option>
                        </select>
                        <inline-validation elementid="a${this.entitlementid}" />
                    </div>
                    <inline-popover poptext="${this.entitlementpoptext}" />
                </div>
                `;
        }
    }

    // Register custom element '<license-manager-simple elementid="myelementid" />'
    customElements.define('auto-login', AutoLogin);

    return AutoLogin;
});
