/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "bootstrap",
  "wizard/uiGenerator",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "computeResource/computeValueFormatter",
  "templates/editConfigFormTemplate",
  "service/cloudStatus"
], function ( _, $, Backbone, Bootstrap, UIGenerator, Util,
              I18NStringResource, DojoString,
              ComputeValueFormatter, EditConfigFormTemplate, CloudStatus ) {

  class EditConfigForm extends Backbone.View {

    constructor (args) {
      super(args);
      this.template = EditConfigFormTemplate;
      if (!args || typeof args !== 'object') {
        throw new TypeError("Invalid arguments");
      }
      this.currentConfigSettings = null;
      this.currentStatus = CloudStatus.Enum.UNKNOWN;
      if (("currentConfigSettings" in args) && typeof args.currentConfigSettings === 'object') {
        this.currentConfigSettings = args.currentConfigSettings;
        if (this.currentConfigSettings && this.currentConfigSettings.cloudData && this.currentConfigSettings.cloudData.state) {
          const statusData = {
            state: this.currentConfigSettings.cloudData.state
          };
          this.currentStatus = CloudStatus.getStatus(statusData);
        }
      }
      this.step2UIElementData = null;
      if (("step2UIElementData" in args) && typeof args.step2UIElementData === 'object') {
        this.step2UIElementData = args.step2UIElementData;
      }
      this.optionDataBuilder = null;
      if (("optionDataBuilder" in args) && typeof args.optionDataBuilder === 'object') {
        this.optionDataBuilder = args.optionDataBuilder;
      }
      this.updateConfig = () => {};
      this.uiGenerator = null;
      if (args.updateConfigMethod && typeof args.updateConfigMethod === 'function') {
        this.updateConfig = args.updateConfigMethod;
      }
      this.closeMethod = () => {};
      if (args.closeMethod && typeof args.closeMethod === 'function') {
        this.closeMethod = args.closeMethod;
      }
      this.dduxLogger = { logData: () => {} };
      if (args.dduxLogger && typeof args.dduxLogger === 'object' && args.dduxLogger.logData && typeof args.dduxLogger.logData === 'function') {
        this.dduxLogger = args.dduxLogger;
      }

      this.events = {
        "click form.editConfigForm div.buttonContainer.editForm > button#cancelEditBtn": "closeEditForm",
        "click form.editConfigForm div.buttonContainer.editForm > button#saveEditBtn": "onSaveEditForm",
        "click form.editConfigForm div.buttonContainer.editForm > button#startEditBtn": "startEditForm",
        "change form.editConfigForm select#VPC" : "onEditFormSelectChanged",
        "change form.editConfigForm select#Subnet" : "onEditFormSelectChanged",
        "change form.editConfigForm select#virtualNetworkResourceID" : "onEditFormSelectChanged",
        "change form.editConfigForm select#subnetName" : "onEditFormSelectChanged",
        "blur form.editConfigForm input[type=\"text\"]#mw-name": "onMlNameInputBlur",
        "focus form.editConfigForm input[type=\"text\"]#mw-name": "onMlNameInputFocus",
        "click form.editConfigForm button.warning-notification": "onWarningNotificationButtonClick"
      };
      _.bindAll(this, "closeEditForm", "onSaveEditForm", "startEditForm",
                "onEditFormSelectChanged", "onMlNameInputBlur", "onMlNameInputFocus");
    }

    getDDUXLogger () { return this.dduxLogger; }

    getCurrentConfigSettings () { return this.currentConfigSettings; }

    getStep2UIElementData () { return this.step2UIElementData; }

    getOptionDataBuilder () { return this.optionDataBuilder; }

    getUpdateConfigMethod () { return this.updateConfig; }

    getCloseMethod () { return this.closeMethod; }

    getStatus () { return this.currentStatus; }

    getUIGenerator () {
      return this.uiGenerator;
    }
    setUIGenerator (generator) {
      this.uiGenerator = generator;
    }

    renderHTML() {
      let formHtml = "";
      if (this.getCurrentConfigSettings()) {
        formHtml = this.template({
          i18n: I18NStringResource,
          Util: Util,
          DojoString: DojoString,
          data: this.getCurrentConfigSettings(),
          formatter: new ComputeValueFormatter(),
          showStartButton: (this.getStatus() === CloudStatus.Enum.TERMINATED)
        });
      }
      return formHtml;
    }

    async render () {
      let formHtml = this.renderHTML();
      this.$el.empty().html(formHtml);
      await this.postRender();
    }

    async postRender () {
      if (this.getCurrentConfigSettings() && this.getStep2UIElementData() && this.getOptionDataBuilder()) {
        const uiSections = this.getStep2UIElementData().uiSections;
        const settingsMap = this.getStep2UIElementData().settingsMap;
        const formElement = this.el.querySelector('form[name="editConfigForm"]');
        const generatedCodeContainer = formElement.querySelector('div#cloudResourceConfig');
        const uiGeneratorArgs = {
          uiSections: uiSections,
          settingsMap: settingsMap,
          dependentOptionHandlerFn: this.getOptionDataBuilder().loadDependentOptions.bind(this.getOptionDataBuilder()),
          inEditMode: true,
          baseElement: generatedCodeContainer,
          fieldValidationHandlers: {
            validFieldHandler: EditConfigForm.enableEditFormButtons,
            invalidFieldHandler: EditConfigForm.disableEditFormButtons
          },
          dduxLogger: this.getDDUXLogger(),
          dataGetters: null
        };
        const uiGenerator = new UIGenerator(uiGeneratorArgs);
        this.setUIGenerator(uiGenerator);
        const generatedUI = this.getUIGenerator().render(undefined, document.createElement('div'), this.getCurrentConfigSettings());
        // Wait for all custom/composite elements to appear in the DOM before proceeding
        await this.getUIGenerator().allCustomElementsAreUpdated();
        const queryMap = this.getUIGenerator().getDependentOptionQueryMap();
        this.getUIGenerator().disableDependentOptions();
        await this.getOptionDataBuilder().loadNonDependentOptions(
          queryMap,
          settingsMap,
          this.getCurrentConfigSettings(),
          true
        );
        this.getUIGenerator().start();
        this.getOptionDataBuilder().updateDependentOptions(queryMap);
        this.getOptionDataBuilder().forceLoadOfDependentOptions(queryMap);
        this.getOptionDataBuilder().setDependentOptionValues(queryMap, this.getCurrentConfigSettings());

        Util.initializePopovers();

        const instanceTypeSelect = this.el.querySelector("div.section2InputContainer.aws_ec2_instance_type select");
        if (instanceTypeSelect) {
          const debouncedOptionsLoaded = _.debounce(EditConfigForm.enableEditFormButtons, 1000).bind(this);
          instanceTypeSelect.addEventListener("optionsLoaded", debouncedOptionsLoaded, false);
        }
      }
    }

    mwNameIsValid (mlNameInput) {
      let isValid = false;
      let regex = new RegExp("^(\\w+).*$");
      if (mlNameInput && ("value" in mlNameInput)) {
        let value = mlNameInput.value;
        isValid = regex.test(value);
      }
      return isValid;
    }

    onMlNameInputBlur (event) {
      if (event && event.target) {
        let mlNameInput = event.target;
        let isValid = this.mwNameIsValid(mlNameInput);
        if (!isValid) {
          let stepContainer = mlNameInput.parentElement;
          Util.displayValidationError(stepContainer, I18NStringResource.cmlWizardStep2InvalidItem, false);
          EditConfigForm.disableEditFormButtons();
        } else {
          EditConfigForm.enableEditFormButtons();
        }
      }
    }

    onMlNameInputFocus (event) {
      if (event && event.target) {
        let stepContainer = event.target.parentElement;
        if (stepContainer) {
          Util.hideValidationError(stepContainer);
        }
      }
    }

    onEditFormSelectChanged (event) {
      EditConfigForm.disableEditFormButtons();
    }

    onSaveEditForm (event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      this.saveEditForm(event);
    }

    onWarningNotificationButtonClick(event) {
      return Util.onWarningNotificationButtonClick(event);
    }

    async saveEditForm (event, startResource=false) {
      Util.consoleLogTrace('saveEditForm', 'called');
      const mlNameInput = this.el.querySelector(`input[name="mw-name"]`)
      if (this.mwNameIsValid(mlNameInput) && this.getUIGenerator() && this.getUIGenerator().getInlineValidationManager().validateInputs(true)) {
        EditConfigForm.disableEditFormButtons();
        let thereAreChanges = false;
        const unchangedFields = [];
        const changedFields = [];
        // get the current form values
        let newValues = this.getFieldValues();
        // Iterate through the values and note what
        // changed and what did not change
        let entries = newValues.entries();
        let entry = entries.next();
        while (!entry.done) {
          let elementId = entry.value[0];
          let newVal = _.unescape(entry.value[1]);
          let origVal = this.getCurrentConfigSettings()[elementId];
          if (newVal !== origVal) {
            changedFields.push(entry.value[0]);
            thereAreChanges = true;
          } else {
            unchangedFields.push(entry.value[0]);
          }
          entry = entries.next();
        }
        // Remove all unchanged fields
        for(const fieldName of unchangedFields) {
          newValues.delete(fieldName);
        }
        // Log which fields are being updated
        const changedFieldsMsg = `Fields being updated: ${changedFields}`;
        Util.consoleLogTrace('saveEditForm', changedFieldsMsg);
        // Do the update of the changed fields
        try {
          if (thereAreChanges) {
           await this.getUpdateConfigMethod()(newValues, startResource);
          } else {
            await this.getUpdateConfigMethod()(undefined, startResource);
          }
        } catch (error) {
          Util.consoleLogError('saveEditForm', error);
        } finally {
          EditConfigForm.enableEditFormButtons();
        }
      }
    }

    startEditForm (event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      this.saveEditForm(event, true);
    }

    closeEditForm (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      this.remove();
      this.getCloseMethod()();
    }

    getFieldValues () {
      const form = this.el.querySelector('form[name="editConfigForm"]');
      const formData = new FormData(form);
      const keys = formData.keys();
      let key = keys.next();
      while (!key.done) {
        const value = formData.get(key.value);
        const escapedValue = _.escape(value);
        formData.set(key.value, escapedValue);
        key = keys.next();
      }
      // Normally, checkboxes are grouped together, e.g., "Pick the cars you like: car1, car2, car3", where "car<x>" is a checkbox and groupname is "carsIlike".
      // In that case, only the checked, i.e., selected, checkboxes are included in the formData.
      // In this case, we have one checkbox -- true/false, "yes"/ "no".  So, we want to include it even if it is not checked.
      // We do this by marking it special with the attribute 'data-checkboxtype="yesno"' and manually add it to the formData when it's not checked.
      const yesNoCheckboxes = form.querySelectorAll('input[type="checkbox"][data-checkboxtype="yesno"]');
      yesNoCheckboxes.forEach(cb => {
        if (!cb.checked && !formData.get(cb.id)) {
          const name = cb.id;
          const value = cb.value;
          formData.append(name, value);
        }
      });
      return formData;
    }

    static disableEditFormButtons () {
      const saveButton = document.querySelector('button#saveEditBtn');
      const startButton = document.querySelector('button#startEditBtn');
      const updateTerminationPolicyButton = document.querySelector('button#updateTerminationPolicyButton')
      if (saveButton) {
        saveButton.disabled = true;
        saveButton.classList.add('disabled');
      }
      if (startButton) {
        startButton.disabled = true;
        startButton.classList.add('disabled');
      }
      if (updateTerminationPolicyButton) {
        updateTerminationPolicyButton.disabled = true;
        updateTerminationPolicyButton.classList.add('disabled');
      }
    }

    static enableEditFormButtons () {
      const saveButton = document.querySelector('button#saveEditBtn');
      const startButton = document.querySelector('button#startEditBtn');
      const updateTerminationPolicyButton = document.querySelector('button#updateTerminationPolicyButton')
      if (saveButton) {
        saveButton.disabled = false;
        saveButton.classList.remove('disabled');
      }
      if (startButton) {
        startButton.disabled = false;
        startButton.classList.remove('disabled');
      }
      if (updateTerminationPolicyButton) {
        updateTerminationPolicyButton.disabled = false;
        updateTerminationPolicyButton.classList.remove('disabled');
      }

    }

  }

  return EditConfigForm;
});
